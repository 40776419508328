<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : '修改'"
    :close-on-click-modal="false"
    :visible.sync="visible">
    <el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="100px">
    <el-form-item label="名称" prop="name">
      <el-input v-model="dataForm.name" placeholder="名称"></el-input>
    </el-form-item>
    <el-form-item label="版本" prop="version">
      <el-input v-model="dataForm.version" placeholder="版本"></el-input>
    </el-form-item>
    <el-form-item label="设备" prop="deviceId">
      <el-select v-model="dataForm.deviceId" placeholder="设备" >
        <el-option v-for="(option,index) in deviceList || []"
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
    </el-form-item>
    <!-- <el-form-item label="ID询问规则" prop="idruleId">
      <el-select v-model="dataForm.idruleId" placeholder="询问规则" >
        <el-option v-for="(option,index) in idruleList || []"
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
    </el-form-item> -->
    <!-- <el-form-item label="升级规则" prop="ruleId">
      <el-select v-model="dataForm.ruleId" placeholder="升级规则" >
        <el-option v-for="(option,index) in ruleList || []"
                    :key="index" :label="option.name" :value="option.id"></el-option>
      </el-select>
    </el-form-item> -->
    <el-form-item label="状态" prop="state">
      <el-select v-model="dataForm.state" >
        <el-option label="公共" :value="1"></el-option>
        <el-option label="定制" :value="2"></el-option>
        <el-option label="禁用" :value="0"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="固件文件" prop="filepath">
      <!-- <el-input v-model="dataForm.filepath" placeholder="文件路径"></el-input> -->
      <upload-file v-model="dataForm.filepath" path="firmware"></upload-file>
    </el-form-item>
    <el-form-item label="升级说明" prop="descr">
      <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 5}" v-model="dataForm.descr" placeholder="升级说明"></el-input>
    </el-form-item>
    <el-form-item label="事件规则" prop="events">
      <div style="margin-bottom: 10px;">
        小程序固件列表点击事件：
        <el-select v-model="dataForm.events.firmwareListClick" placeholder="交互规则" filterable>
          <el-option v-for="(option,index) in ruleList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </div>
      <div style="margin-bottom: 10px;">
        小程序点击升级按钮事件：
        <el-select v-model="dataForm.events.firmwareUpdate" placeholder="交互规则" filterable>
          <el-option v-for="(option,index) in ruleList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </div>
      <div>
        小程序点击升级按钮成功事件：
        <el-select v-model="dataForm.events.firmwareUpdateAfter" placeholder="交互规则" filterable>
          <el-option v-for="(option,index) in ruleList || []"
                      :key="index" :label="option.name" :value="option.id"></el-option>
        </el-select>
      </div>
    </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
  import uploadFile from '@/components/upload-file'
  export default {
    components:{
      uploadFile
    },
    data () {
      return {
        visible: false,
        dataForm: {
          id: 0,
          name: '',
          version: '',
          deviceId: '',
          descr: '',
          filepath: '',
          ruleId: '',
          state: '',
          idruleId: '',
          events: {},
        },
        dataRule: {
          name: [
            { required: true, message: '名称不能为空', trigger: 'blur' }
          ],
          version: [
            { required: true, message: '版本不能为空', trigger: 'blur' }
          ],
          deviceId: [
            { required: true, message: '设备id不能为空', trigger: 'blur' }
          ],
          descr: [
            // { required: true, message: '升级说明不能为空', trigger: 'blur' }
          ],
          filepath: [
            { required: true, message: '文件路径不能为空', trigger: 'blur' }
          ],
          ruleId: [
            { required: true, message: '升级规则id不能为空', trigger: 'blur' }
          ],
          state: [
            { required: true, message: '状态不能为空', trigger: 'blur' }
          ],
          idruleId: [
            { required: true, message: 'ID询问规则不能为空', trigger: 'blur' }
          ],
        },
        ruleList:[],//升级规则集合
        deviceList:[],//设备集合
      }
    },
    methods: {
      init (id) {
        this.dataForm.id = id || 0
        this.visible = true
        this.$nextTick(() => {
          this.$refs['dataForm'].resetFields()
          if (this.dataForm.id) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsfirmware/info/${this.dataForm.id}`),
              method: 'get',
              params: this.$http.adornParams()
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.dataForm.name = data.wxsFirmware.name
                this.dataForm.version = data.wxsFirmware.version
                this.dataForm.deviceId = data.wxsFirmware.deviceId
                this.dataForm.descr = data.wxsFirmware.descr
                this.dataForm.filepath = data.wxsFirmware.filepath
                // this.dataForm.ruleId = data.wxsFirmware.ruleId
                this.dataForm.state = data.wxsFirmware.state
                // this.dataForm.idruleId = data.wxsFirmware.idruleId
                this.dataForm.events = JSON.parse(data.wxsFirmware.events)||{}
              }
            })
          }
        })
      },
      // 表单提交
      dataFormSubmit () {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            this.$http({
              url: this.$http.adornUrl(`/wxs/wxsfirmware/${!this.dataForm.id ? 'save' : 'update'}`),
              method: 'post',
              data: this.$http.adornData({
                'id': this.dataForm.id || undefined,
                'name': this.dataForm.name,
                'version': this.dataForm.version,
                'deviceId': this.dataForm.deviceId,
                'descr': this.dataForm.descr,
                'filepath': this.dataForm.filepath,
                // 'ruleId': this.dataForm.ruleId,
                'state': this.dataForm.state,
                // 'idruleId': this.dataForm.idruleId,
                'events': JSON.stringify(this.dataForm.events),
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                this.$message({
                  message: '操作成功',
                  type: 'success',
                  duration: 1500,
                  onClose: () => {
                    this.visible = false
                    this.$emit('refreshDataList')
                  }
                })
              } else {
                this.$message.error(data.msg)
              }
            })
          }
        })
      },
      loadRuleList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsrule/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.ruleList = data.page.list
          }
        })
      },
      loadDeviceList(){
        this.$http({
          url: this.$http.adornUrl('/wxs/wxsdevices/list'),
          method: 'get',
          params: this.$http.adornParams({
            'page': 0,
            'limit': 99999,
          })
        }).then(({data}) => {
          if (data && data.code === 0) {
            this.deviceList = data.page.list
          }
        })
      },
    },
    created() {
      this.loadRuleList()
      this.loadDeviceList()
    }
  }
</script>
